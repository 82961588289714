import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { contentBannerCta } from './index.module.scss'

const ContentBannerEnterprise = props => (
  <Link to="/enterprise" className={contentBannerCta}>
    <div className={`inner-wrapper ${props.reverse ? 'reverse' : ''}`}>
      <div className="content">
        <span className="headline">Empower your entire organization for the digital age</span>
        <span className="description">Benefit form a scalable and customizable digital work platform and get custom connectors for all your existing SaaS solutions.</span>

        <span className="linkBtn primary">Explore all enterprise benefits</span>
      </div>
      <div className="media-container">
        <StaticImage className="media-item" src="../../assets/images/basaas-spider-v2.png" alt="Build your tailor-made productivity solution" placeholder="blurred" />
      </div>
    </div>
  </Link>
)

// ContentBlockAlternating.propTypes = {
//   mediaUrl: PropTypes.string,
//   mediaAlt: PropTypes.string,
//   kicker: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   body: PropTypes.string,
//   isExternalLink: PropTypes.bool,
//   linkTo: PropTypes.string,
//   linkText: PropTypes.string,
// };

// ContentBannerEnterprise.defaultProps = {
//   headline: 'Start today with basaas for free',
//   description: 'Sign-up and start revolutionizing the way you work.',
//   actionLinkIsExternal: true,
//   actionLink: 'https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=footer&utm_campaign=signup&utm_content=home',
//   actionLinkText: "Get started – It's free!",
//   // media: <StaticImage src="../assets/images/app-icons.svg" alt="Optimize your app management" placeholder="blurred" />,
// }

export default ContentBannerEnterprise
